import { Instance, types } from 'mobx-state-tree'
import {
  CbwTransactionStatus,
  PaymentInitiator,
  PaymentMethods,
} from '../dictionaries'
import { MomentDate } from '../types/Moment'

export interface OperationMetadata {
  payee_id?: string
  payer_id?: string
  payment_method?: PaymentMethods
  pullResult?: Date
  paymentInitiator?: PaymentInitiator
  test?: boolean
  lms_paymentId?: string
  paymentDate?: string
  achDiscount?: number
  originalReference?: string
}

export const Operation = types.model('Operation', {
  id: '',
  owner_id: '',
  date: types.maybeNull(MomentDate),
  // optimization for search / sort by date of the very first transaction
  firstTransactionDate: types.maybeNull(MomentDate),
  type: '',
  amount: 0.0,
  currency: '',
  status: '',
  metadata: types.frozen<OperationMetadata>(),
})

export const Transaction = types.model('Transaction', {
  operation_id: '',
  type: '',
  payer_id: '',
  payee_id: '',
  amount: 0.0,
  currency: '',
  fee: 0.0,
  date: types.maybeNull(MomentDate),
  payment_method: '',
  reason: '',
  status: '',
  paymentTransactionId: types.maybeNull(types.string),
  payment_provider: types.maybeNull(types.string),
  metadata: types.frozen<{
    statusData: {
      api?: {
        originalReference: string
        reference: string
        dateTime: string
      }
      transactionNumber?: string
      transactionAmountCents?: string
      transactionStatus: CbwTransactionStatus
    }
    nextTransaction?: string
    payee_id?: string
    payer_id?: string
    payment_method?: 'card' | 'ach'
    isAchInternalCreated?: boolean
    tabapayBin?: string
    error?: any
    payload?: string
    accountId?: string
    transactionType?: string
    transactionNumber?: string
    sourceAccountId?: string
    transactionID?: string
  }>(),
})

export interface IOperationModel extends Instance<typeof Operation> {}

export interface ITransactionModel extends Instance<typeof Transaction> {}
