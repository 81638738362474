import errors from '../locales/en/errors.json'

export type ErrorCode = keyof typeof errors

export const isKnownError = (code: any): code is ErrorCode =>
  !!errors[code as ErrorCode]

export class SystemError extends Error {
  code: ErrorCode

  constructor(code: ErrorCode, message?: string) {
    super(message)
    this.code = code
    this.name = this.constructor.name
  }
}

export class RetryLaterError extends SystemError {
  constructor(code: ErrorCode = 'retry-later') {
    super(code)
  }
}

export class AuthorizationError extends SystemError {
  constructor(code: ErrorCode = 'not-authorized') {
    super(code)
  }
}

export class AuthenticationError extends SystemError {
  constructor(code: ErrorCode = 'not-authenticated') {
    super(code)
  }
}

export class AdminRequiredError extends SystemError {
  constructor() {
    super('admin-required')
  }
}

export class RoleRequiredError extends SystemError {
  constructor(roleKey: string) {
    super('role-required', roleKey)
  }
}

export class InvitationError extends SystemError {
  constructor(code: ErrorCode = 'retry-later') {
    super(code)
  }
}

export class LogicalError extends SystemError {
  constructor(code: string | ErrorCode) {
    if (isKnownError(code)) {
      super(code as ErrorCode, errors[code])
    } else {
      super('unknown', code)
    }
  }
}

export class DuplicateError extends SystemError {
  constructor(code: ErrorCode = 'duplicate') {
    super(code)
  }
}

export class InsufficientFundsError extends SystemError {
  constructor(code: ErrorCode = 'insufficient-funds') {
    super(code)
  }
}

export class FeatureNotAvailable extends SystemError {
  constructor() {
    super('feature-turned-off')
  }
}

export class BatchError extends SystemError {
  constructor(reason: string[] = []) {
    super('unknown', reason.join('\n'))
  }
}

export class DeniedError extends SystemError {
  constructor(code: ErrorCode = 'operation/denied') {
    super(code)
  }
}
