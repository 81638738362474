export const customerStatus = {
  draft: 'DRAFT',
  active: 'ACTIVE',
  inActive: 'INACTIVE',
  invited: 'INVITED',
  saved: 'SAVED',
  deleted: 'DELETED',
  new: 'NEW',
  activeCredit: 'ACTIVE CREDIT',
} as const

export type CustomerStatus = typeof customerStatus[keyof typeof customerStatus]
