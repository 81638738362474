import {
  validateEmail,
  validateUSPhoneNumber,
} from '@linqpal/models/src/helpers/validations'
import { parsePhoneNumber } from 'libphonenumber-js'
import { IDisposer, Instance, onPatch, types } from 'mobx-state-tree'
import { editableModel, exceptions, routes, routes2 } from '@linqpal/models'
import moment from 'moment/moment'
import RootStore from '../../../store'
import fb from '../../../utils/service/fb'
import validatePassword from '../validatePassword'
import persistModel from '../../../store/persistable'
import { errorHolder } from '../../../store/errorholder'
import makeInspectable from 'mobx-devtools-mst'
import cooker from '../../../utils/service/cooker'
import { currentZDate } from '@linqpal/models/src/helpers/date'
import { callCustomEvent } from '../../../utils/helpers/callCustomEvent'
import { getUserAgent } from '../../../utils/helpers/userAgent'
import { invitationTypes } from '@linqpal/models/src/dictionaries'

const errorTargets = ['password', 'login', 'verify', 'signup']

interface Customer {
  first_name: string
  last_name: string
  phone: string
  email: string
  name: string
  invoiceId: string
}

function views(_self) {
  const self = _self as ISignUpStoreModel
  return {
    get isValidLogin(): boolean {
      return (
        (self.type === 'password' && validateEmail(self.login)) ||
        (self.type === 'code' && validateUSPhoneNumber(self.login))
      )
    },
    get isValidEmail(): boolean {
      return validateEmail(self.email)
    },
    get isValidPhone(): boolean {
      return validateUSPhoneNumber(self.phone)
    },
    get isValidPassword(): boolean {
      let valid = false
      let check
      if (self.type === 'password') {
        check = validatePassword(self.password)
        valid = check.length && check.uppercase && check.number
      } else {
        valid = self.password.length === 6
      }
      return valid
    },
    get passwordValidation() {
      if (!self.password) return null
      return validatePassword(self.password)
    },
    get canSubmit(): boolean {
      if (self.type === 'password')
        return self.isValidLogin && self.isValidPassword
      if (self.type === 'code') return self.isValidLogin && !!self.codeVerifier
      return false
    },
    get canContinueSignup(): boolean {
      if (self.type === 'code')
        return (
          self.canSubmit &&
          self.isValidPassword &&
          !self.errors.get('verify') &&
          !RootStore.isBusy
        )
      return false
    },
    get canSignUp(): boolean {
      const validForm =
        self.firstName.trim().length > 0 &&
        self.lastName.trim().length > 0 &&
        self.isBusinessNameFilled &&
        self.isValidPhone &&
        self.isValidEmail
      if (self.type === 'password') {
        return validForm && self.canSubmit
      }
      if (self.type === 'code') {
        return validForm && self.fbUser !== null
      }
      return false
    },
    get phoneNumber() {
      try {
        const phoneNumber = parsePhoneNumber(self.phone, 'US')
        return phoneNumber.number.toString()
      } catch (e) {
        return null
      }
    },
    get isBusinessNameFilled() {
      if (self.isInvitation && self.isWithinCompanyInvitation) return true
      return !self.isBusiness || self.company_name.trim().length > 0
    },
    get isInvitationOrInvoiceLinkSignup() {
      return self.isInvitation || self.signupInvoiceId
    },
    get isInvitationFromApplicationForm() {
      return (
        self.isInvitation &&
        [
          invitationTypes.GETPAID_INVITE,
          invitationTypes.CREDIT_INVITE,
        ].includes(self.invitationType || '')
      )
    },
    get isWithinCompanyInvitation() {
      // User will be signed up as another user in the same company who sent invite - new company NOT created
      return [
        invitationTypes.SUPPLIER_INVITE_USER,
        invitationTypes.GETPAID_INVITE,
        invitationTypes.CREDIT_INVITE,
      ].includes(self.invitationType || '')
    },
  }
}

function actions(_self) {
  const self = _self as ISignUpStoreModel
  const disposers = new Array<IDisposer>()

  return {
    afterCreate() {
      disposers.push(
        onPatch(self, (patch) => {
          switch (patch.path) {
            case '/login':
              if (self.type === 'password' && validateEmail(patch.value)) {
                self.setValue('email', patch.value)
              } else if (
                self.type === 'code' &&
                validateUSPhoneNumber(patch.value)
              ) {
                self.setValue('phone', patch.value)
              }
              break
            case '/type':
              if (patch.value === 'password' && !validateEmail(self.login)) {
                self.login = ''
              }
              if (
                patch.value === 'code' &&
                !validateUSPhoneNumber(self.login)
              ) {
                self.login = ''
              }
          }
        }),
      )
    },
    beforeDestroy() {
      disposers.forEach((d) => d())
    },
    setLogin(value) {
      self.login = value
    },
    setPassword(value) {
      self.password = value
    },
    checkLoginAttempts() {
      if (getLoginAttempts(self.login) < 5) return
      throw new exceptions.LogicalError('auth/too-many-requests')
    },
    async onSingInError(error) {
      if (!error?.code) throw error
      try {
        incrLoginAttempts(self.login)
      } catch {}
      throw error
    },
    async checkUserSignUp(verifier) {
      self.removeError('login')

      try {
        self.checkLoginAttempts()
      } catch (error: any) {
        self.setError('login', 'error', error.code)
        return Promise.reject(error)
      }

      const promise = routes2.user
        .check({ login: self.login.toLowerCase() })
        .then((data) => {
          if (data.exists) {
            const code = validateEmail(self.login.toLowerCase())
              ? 'email-already-in-use'
              : 'phone-already-in-use'
            self.setError('login', 'error', code)
            return Promise.reject(code)
          }
          return Promise.resolve()
        })
      if (self.type === 'code') {
        return promise.then(() => self.initPhoneVerify(verifier))
      }
      return promise
    },
    async signUp(invoiceId?: string) {
      RootStore.setBusy(true)
      self.removeError('signup')
      if (!self.canSignUp && !invoiceId) {
        self.setError('general', 'error', 'error/not-filled')
      }
      let promise
      if (self.type === 'password') {
        promise = self.signUpWithPassword()
      } else if (self.type === 'code') {
        promise = Promise.resolve(self.fbUser)
      }
      if (!promise) {
        return Promise.reject('Signup method no implemented')
      }
      return promise
        .then(async (user) => {
          if (user?._delegate?.providerData[0]?.providerId) {
            callCustomEvent(
              `signup_${user._delegate.providerData[0].providerId}`,
            )
          }
          const idToken = await user.getIdToken()
          if (!self.phoneNumber) {
            return
          }
          return routes2.user.signup({
            type: self.type,
            firstName: self.firstName,
            lastName: self.lastName,
            email: self.email.toLowerCase(),
            phone: self.phoneNumber,
            idToken,
            invitation: self.invitation,
            opt_for_marketing_messages: self.opt_for_marketing_messages,
            isBusiness: self.isBusiness,
            company_name: self.company_name,
            invoiceId: invoiceId || undefined,
          })
        })
        .then(({ session, challenge }) => {
          cooker.set('session', session, { maxAge: 60 * 60 })
          cooker.set('auth-challenge', challenge, {
            maxAge: 80 * 24 * 60 * 60,
          })
          const conversion = cooker.get('conversion') || {}
          routes.user
            .conversion({
              lastLogin: currentZDate(),
              referalCode: self.referalCode,
              ...conversion,
            })
            .finally(() => cooker.remove('conversion'))
          return routes2.user.info()
        })
        .then((data) => {
          RootStore.userStore.setInfo(data)
          RootStore.userStore.setAuthState(data)
        })
        .catch((e) => {
          if (
            e.code === 'phone-already-in-use' ||
            e.code === 'email-already-in-use'
          ) {
            self.setError('login', 'error', e.code)
          } else {
            RootStore.setError('general', 'error', e.message)
          }
        })
        .finally(() => RootStore.setBusy(false))
    },
    async signUpWithPassword() {
      return fb
        .auth()
        .createUserWithEmailAndPassword(self.login.toLowerCase(), self.password)
        .catch((e) => {
          self.setError('signup', 'error', e.code)
          return fb
            .auth()
            .signInWithEmailAndPassword(self.login.toLowerCase(), self.password)
        })
        .then(({ user }) => {
          if (user) {
            user.updateProfile({
              displayName: `${self.firstName} ${self.lastName}`,
            })
            return Promise.resolve(user)
          }
          return Promise.reject('Registration failed')
        })
        .catch((e) => self.setError('general', 'error', e.message))
    },
    async initPhoneVerify(verifier?) {
      return fb
        .auth()
        .signInWithPhoneNumber(self.phoneNumber as string, verifier)
        .then(async (codeVerifier) => {
          console.log('codeVerifier', codeVerifier)
          self.setCodeVerifier(codeVerifier)
          RootStore.setBusy(false)
          return Promise.resolve(codeVerifier)
        })
    },
    async verifyPhone() {
      RootStore.setBusy(true)
      self.removeError('verify')
      return (self.codeVerifier as unknown as fb.auth.ConfirmationResult)
        .confirm(self.password)
        .catch(self.onSingInError)
        .then(({ user }) => {
          user?.updateProfile({
            displayName: `${self.firstName} ${self.lastName}`,
          })
          self.setValue('fbUser', user)
          return Promise.resolve(user)
        })
        .catch((e: any) => {
          self.setError('verify', 'error', e.code)
          return Promise.reject(e)
        })
        .finally(() => RootStore.setBusy(false))
    },
    setCodeVerifier(verifier) {
      self.codeVerifier = verifier
    },
    setIsBusiness() {
      self.isBusiness = !self.isBusiness
    },
    setUpAutoPhoneSignup(login, codeVerifier, user) {
      self.setValue('fbUser', user)
      self.setLogin(login)
      self.setCodeVerifier(codeVerifier)
      self.setValue('type', 'code')
      self.setValue('phone', login)
    },
    setUpInvoiceLinkSignup(customer: Customer) {
      const userAgent = getUserAgent()
      const setEmail = () => {
        self.setLogin(customer?.email)
        self.setValue('type', 'password')
      }
      const setPhone = () => {
        self.setLogin(customer?.phone)
        self.setValue('type', 'code')
      }
      if (userAgent.includes('Macintosh') || userAgent.includes('Win')) {
        if (validateEmail(customer?.email)) setEmail()
        else setPhone()
      } else {
        if (validateUSPhoneNumber(customer?.phone)) setPhone()
        else setEmail()
      }
      self.setValue('firstName', customer?.first_name)
      self.setValue('lastName', customer?.last_name)
      self.setValue(
        'phone',
        customer?.phone
          ? parsePhoneNumber(customer?.phone, 'US').nationalNumber
          : '',
      )
      self.setValue('email', customer?.email)
      self.setValue('company_name', customer?.name || '')
      self.setValue('signupInvoiceId', customer?.invoiceId)
    },
  }
}

function getLoginAttempts(login: string) {
  const name = encodeURIComponent(`login-attempts:${login}`)
  return parseInt(cooker.get(name) || '0') || 0
}

function incrLoginAttempts(login: string) {
  const name = encodeURIComponent(`login-attempts:${login}`)
  const attempts = getLoginAttempts(login) + 1
  cooker.set(name, attempts.toString(), {
    maxAge: moment().add(1, 'day').diff(moment(), 'seconds'),
  })
}

export const SignupStoreModel = types
  .compose(
    types
      .model({
        login: '',
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        isInvitation: types.maybeNull(types.optional(types.boolean, false)),
        invitation: types.maybeNull(types.optional(types.string, '')),
        invitationType: types.maybeNull(types.optional(types.string, '')),
        signupInvoiceId: types.optional(types.string, ''),
        isBusiness: true,
        referalCode: '',
        opt_for_marketing_messages: false,
        company_name: '',
        type: types.optional(
          types.enumeration(['password', 'code']),
          'password',
        ),
        receiveMessage: types.optional(types.boolean, false),
      })
      .volatile(() => ({ password: '', codeVerifier: null, fbUser: null }))
      .views(views)
      .actions(actions),
    editableModel(),
    errorHolder(errorTargets, false),
    persistModel('BT_SIGNUP', true),
  )
  .named('SignupStore')
const store = SignupStoreModel.create()
makeInspectable(store)

interface ISignUpStoreModel extends Instance<typeof SignupStoreModel> {}

export default store
