import { PurchaseTypes } from '../dictionaries'
import { CustomerAccountInfo } from '../dtos/CustomerAccounts'
import { delete_, get, patch, post, put } from '../helpers/caller'
import { EInvoiceType, IInstitutionModel, PricingPackageStatuses } from '../mst'
import {
  IAutoTradeCreditSettings,
  ICustomerSettings,
  IReceivablesRescheduleItem,
  ISupplier,
  RepaymentType,
} from '../types/routes'
import { IUserRole } from './userRole'

interface IPaymentsParams {
  search: string
  status: string
  page: number
  pageSize: number
  dateStart: string
  dateEnd: string
  type?: string
  types?: string[]
  methods?: string[]
}

interface ITransactionsParams {
  dateStart: string
  dateEnd: string
  methods?: string[]
}

export const admin = {
  domain: 'admin',

  applications() {
    return get(admin.domain, admin.applications)
  },

  /**
   * @param search {string}
   * @param page {number}
   * @param limit {number}
   */
  signups(search: string, page: number, limit: number) {
    return get(admin.domain, admin.signups, {
      search,
      page,
      limit,
    })
  },

  /**
   * @param search {string}
   * @param page {number}
   * @param limit {number}
   */
  users(search: string, page: number, limit: number) {
    return get(admin.domain, admin.users, {
      search,
      page,
      limit,
    })
  },

  /**
   * @param companyId {string}
   */
  employees(companyId: string) {
    return get(admin.domain, admin.employees, {
      companyId,
    })
  },

  /**
   * @param sub {string}
   * @param company_id {string}
   */
  userCompanyChange(sub: string, company_id: string) {
    return post(admin.domain, admin.userCompanyChange, {
      sub,
      company_id,
    })
  },

  /**
   * @param sub {string}
   */
  deleteUser(sub: string) {
    return post(admin.domain, admin.deleteUser, { sub })
  },

  verifyUserEmail(sub: string) {
    return post(admin.domain, admin.verifyUserEmail, { sub })
  },

  // TO BE REMOVED
  toggleAdmin(sub: string) {
    return post(admin.domain, admin.toggleAdmin, { sub })
  },

  saveUserRoles(sub: string, roles: IUserRole[]) {
    return put(admin.domain, admin.saveUserRoles, { sub, roles })
  },

  getUserRoles(sub: string) {
    return get(admin.domain, admin.getUserRoles, { sub })
  },

  supplierApplications(
    search: string,
    status: string,
    page: number,
    limit: number,
  ) {
    return get<{ items: any[]; total: number }>(
      admin.domain,
      admin.supplierApplications,
      {
        search,
        status,
        page,
        limit,
      },
    )
  },
  supplierApplicationsDownload(search: string) {
    return get(admin.domain, admin.supplierApplicationsDownload, {
      search,
    })
  },
  supplierApplicationValidate(id: string) {
    return post(admin.domain, admin.supplierApplicationValidate, { id })
  },
  supplierApplicationSendBack(id: string) {
    return post(admin.domain, admin.supplierApplicationSendBack, { id })
  },
  supplierApplicationReject(id: string) {
    return post(admin.domain, admin.supplierApplicationReject, { id })
  },
  supplierApplicationApprove(id: string) {
    return post(admin.domain, admin.supplierApplicationApprove, { id })
  },
  supplierApplicationEdit(document: any) {
    return post(admin.domain, admin.supplierApplicationEdit, document)
  },
  supplierApplicationUpload(companyId: string, fileObject: any) {
    return post(admin.domain, admin.supplierApplicationUpload, fileObject, {
      companyId,
    })
  },
  supplierApplicationUploads(companyId: string) {
    return get(admin.domain, admin.supplierApplicationUploads, { companyId })
  },
  finicityAccounts(search: string, page: number, pageSize: number) {
    return get(admin.domain, admin.finicityAccounts, {
      search,
      page,
      pageSize,
    })
  },
  payments(params: IPaymentsParams) {
    return get(admin.domain, admin.payments, params)
  },
  transactions(params: ITransactionsParams) {
    return get(admin.domain, admin.transactions, params)
  },
  paymentDecryptPayload(transaction_id: string) {
    return get(admin.domain, admin.paymentDecryptPayload, { transaction_id })
  },
  toggleTestPayment(operation_id: string, test: any) {
    return post(admin.domain, admin.toggleTestPayment, { operation_id, test })
  },
  /**
   * @param sub {string}
   */
  deleteFinicityAccount({
    accountId,
    companyId,
  }: {
    accountId: string
    companyId: string
  }) {
    return get(admin.domain, admin.deleteFinicityAccount, {
      accountId,
      companyId,
    })
  },
  supplierApplicationKYC(companyId: string) {
    return get(admin.domain, admin.supplierApplicationKYC, { companyId })
  },
  remoteConfig() {
    return get(admin.domain, admin.remoteConfig)
  },
  setRemoteConfig(key: string, value: string) {
    return post(admin.domain, admin.remoteConfig, { key, value })
  },
  listKycMemo(companyId: string) {
    return get(admin.domain, admin.listKycMemo, { companyId })
  },
  addKycMemo(companyId: string, comment: string) {
    return post(admin.domain, admin.addKycMemo, { companyId, comment })
  },
  invoices(
    invoiceIds?: string[],
    search?: string,
    atc?: boolean,
    type?: EInvoiceType,
    page?: number,
    pageSize?: number,
    dateStart?: string,
    dateEnd?: string,
    status?: string,
  ) {
    return get(admin.domain, admin.invoices, {
      search,
      atc,
      type,
      page,
      pageSize,
      dateStart,
      dateEnd,
      status,
      invoiceIds,
    })
  },

  getFinicityAccounts(companyId: string) {
    return get(admin.domain, admin.getFinicityAccounts, { companyId })
  },

  getGiactAccountsForCompany(companyId: string) {
    return get(admin.domain, admin.getGiactAccountsForCompany, { companyId })
  },

  loanApplications(
    search: string,
    page: number,
    pageSize: number,
    status: string,
    kind: string,
  ) {
    return get(admin.domain, admin.loanApplications, {
      search,
      status,
      kind,
      page,
      pageSize,
    })
  },
  exportLoanApplications(params: any) {
    return get(admin.domain, admin.exportLoanApplications, {
      ...params,
      page: -1,
      pageSize: -1,
    })
  },
  viewAgreement(data: {
    id: string
    agreement_type?: string
    isAdmin: boolean
  }) {
    return get(admin.domain, admin.viewAgreement, data)
  },
  tryGetLatestMasterAgreement(data: { id: string }) {
    return get(admin.domain, admin.tryGetLatestMasterAgreement, data)
  },
  getMasterAgreementApprovals(companyId: string) {
    return get(admin.domain, admin.getMasterAgreementApprovals, { companyId })
  },
  hasPersonalGuarantorAgreement(companyId: string) {
    return get(admin.domain, admin.hasPersonalGuarantorAgreement, { companyId })
  },
  downloadPersonalGuarantorAgreements(companyId: string) {
    return get(admin.domain, admin.downloadPersonalGuarantorAgreements, {
      companyId,
    })
  },
  submitApprovalRequest(
    documentId: string,
    companyId: string,
    approver: any,
    applicantName: string,
    companyName: string,
  ) {
    return post(admin.domain, admin.submitApprovalRequest, {
      documentId,
      companyId,
      approver,
      applicantName,
      companyName,
    })
  },
  uploadAgreement(applicationId: string) {
    return post(admin.domain, admin.uploadAgreement, { id: applicationId })
  },
  processLoanApplication(data: any) {
    return post(admin.domain, admin.processLoanApplication, data)
  },
  approveLoanApplication(_id: string, amount: number, note: string) {
    return post(admin.domain, admin.approveLoanApplication, {
      _id,
      amount,
      note,
    })
  },
  cancelLoanApplication(_id: string) {
    return post(admin.domain, admin.cancelLoanApplication, { _id })
  },
  resumeDecisionEngine(_id: string, output: any) {
    return post(admin.domain, admin.resumeDecisionEngine, { _id, output })
  },
  uploadFinicityData(company_id: string, data: any) {
    return post(admin.domain, admin.uploadFinicityData, { company_id, data })
  },
  changeLoanApplicationPaymentPlan(data: {
    _id: string
    supplierName: string
    routingNumber: string
    accountNumber: string
    paymentPlan: any
  }) {
    return post(admin.domain, admin.changeLoanApplicationPaymentPlan, data)
  },
  getFinicityTransactions(company_id: string, download = false) {
    return get(admin.domain, admin.getFinicityTransactions, {
      company_id,
      download,
    })
  },
  getPlaidTransactions(bankId: string) {
    return get(admin.domain, admin.getPlaidTransactions, {
      bankId,
    })
  },
  getBankAccounts(companyId: string) {
    return get(admin.domain, admin.getBankAccounts, { companyId: companyId })
  },
  lexisNexisInstantId(company_id: string) {
    return get(admin.domain, admin.lexisNexisInstantId, { company_id })
  },
  lexisNexisEmailAge(company_id: string) {
    return get(admin.domain, admin.lexisNexisEmailAge, { company_id })
  },
  lexisNexisFraudPoint(company_id: string) {
    return get(admin.domain, admin.lexisNexisFraudPoint, { company_id })
  },
  experianData(company_id: string) {
    return get(admin.domain, admin.experianData, { company_id })
  },
  findLexisNexisInstantIdByHashes(hashes: string[]) {
    return post(admin.domain, admin.findLexisNexisInstantIdByHashes, { hashes })
  },
  findLexisNexisEmailAgeByHashes(hashes: string[]) {
    return post(admin.domain, admin.findLexisNexisEmailAgeByHashes, { hashes })
  },
  findLexisNexisFraudPointByHashes(hashes: string[]) {
    return post(admin.domain, admin.findLexisNexisFraudPointByHashes, {
      hashes,
    })
  },
  findExperianDataByHashes(hashes: string[]) {
    return post(admin.domain, admin.findExperianDataByHashes, { hashes })
  },
  settings(data: any) {
    return post(admin.domain, admin.settings, data)
  },
  contractorApplications(company: string) {
    return get(admin.domain, admin.contractorApplications, { company })
  },

  loans(skip: number, pageSize: number) {
    return get(admin.domain, admin.loans, { skip, pageSize })
  },
  loan(id: string) {
    return get(admin.domain, admin.loan, { id })
  },
  paymentPlan(id: string) {
    return get(admin.domain, admin.paymentPlan, { id })
  },
  cardPricingPackage(id: string | null = null) {
    return get(admin.domain, admin.cardPricingPackage, { id })
  },
  loanPricingPackage(
    status: PricingPackageStatuses | null = null,
    individual: boolean | null = null,
    id: string | null = null,
  ) {
    return get(admin.domain, admin.loanPricingPackage, {
      id,
      status,
      individual,
    })
  },
  loanPaymentPlan() {
    return get(admin.domain, admin.loanPaymentPlan)
  },

  /**
   * @typedef {Promise} AdminResponse
   * @property {function} cancel
   */

  /**
   * @param {Object} params
   * @param {number} [params.page]
   * @param {number} [params.limit]
   * @param {string} [params.search]
   * @param {string} [params.sort]
   * @param {boolean} [params.asc]
   * @returns {AdminResponse<{items:[],total:number}>}
   */
  loanStatus({
    page = 1,
    limit = 10,
    sort = 'createdAt',
    asc = true,
    search = '',
    isOverdue = '',
    loanStatus = '',
  }) {
    return get(admin.domain, admin.loanStatus, {
      page,
      limit,
      sort,
      asc,
      search,
      isOverdue,
      loanStatus,
    })
  },

  /**
   * @param {string} id
   * @returns {AdminResponse<{item:*}>}
   */
  loanStatusDetails(id: string) {
    return get(admin.domain, admin.loanStatusDetails, { id })
  },
  getLoanAvailableBalance(id: string) {
    return get(admin.domain, admin.getLoanAvailableBalance, { id })
  },
  importTransactions(companyId: string) {
    return get(admin.domain, admin.importTransactions, { companyId })
  },

  loanPayments(params: { date: string }) {
    return get(admin.domain, admin.loanPayments, params)
  },
  toggleLoanAppPaymentCancelled(id: string, payment_cancelled: boolean) {
    return post(admin.domain, admin.toggleLoanAppPaymentCancelled, {
      id,
      payment_cancelled,
    })
  },
  toggleLoanDisbursementPause(appId: string, enabled: boolean) {
    return post(admin.domain, admin.toggleLoanDisbursementPause, {
      appId,
      enabled,
    })
  },
  toggleLoanSkipFinalPayment(id: string, value: boolean) {
    return post(admin.domain, admin.toggleLoanSkipFinalPayment, {
      id,
      skip_final_payment: value,
    })
  },

  /**
   * @param {string} key - aws file key
   * @returns {Promise<string>}
   */
  async fileDownload(key: string) {
    const { url } = await get(admin.domain, admin.fileDownload, { key })
    return url
  },

  async getBlucognitionAttachmentUrl(key: string) {
    const { url } = await get(
      admin.domain,
      admin.getBlucognitionAttachmentUrl,
      { key },
    )
    return url
  },

  async giactGauthenticate(request: any) {
    const result = await post(admin.domain, admin.giactGauthenticate, {
      request,
    })

    return result
  },

  async triggerDataSendToBlucognition(loanApplicationId: string) {
    return post(admin.domain, admin.triggerDataSendToBlucognition, {
      loanApplicationId,
    })
  },

  async triggerDataSendToBlucognitionForSupplier(applicationId: string) {
    return post(admin.domain, admin.triggerDataSendToBlucognitionForSupplier, {
      applicationId,
    })
  },

  updatePricingPackageAgreement(data: any) {
    return post(admin.domain, admin.updatePricingPackageAgreement, data)
  },

  saveLoanPricingPackage(data: any) {
    return post(admin.domain, admin.saveLoanPricingPackage, data)
  },

  async customers(search = '', approved = false, page = 0) {
    return get(admin.domain, admin.customers, { search, approved, page })
  },
  async saveCustomerBankAccounts(company_id: string, bankAccounts: any) {
    return post(admin.domain, admin.saveCustomerBankAccounts, {
      company_id,
      bankAccounts,
    })
  },
  async saveCustomerAccountingSettings(company_id: string, settings: any) {
    return post(admin.domain, admin.saveCustomerAccountingSettings, {
      company_id,
      settings,
    })
  },
  async saveCustomerCompanySettings(company_id: string, settings: any) {
    return post(admin.domain, admin.saveCustomerCompanySettings, {
      company_id,
      settings,
    })
  },

  createAchPull(data: any) {
    return post(admin.domain, admin.createAchPull, data)
  },
  createAchPayment(data: any) {
    return post(admin.domain, admin.createAchPayment, data)
  },
  createAchOut(data: any) {
    return post(admin.domain, admin.createAchOut, data)
  },
  createAchInternal(data: any) {
    return post(admin.domain, admin.createAchInternal, data)
  },
  rerunAch(data: any) {
    return post(admin.domain, admin.rerunAch, data)
  },
  card({ invoice_id, company_id }: { invoice_id: string; company_id: string }) {
    return get(admin.domain, admin.card, { invoice_id, company_id })
  },

  loanApplicationNotes(appId: string) {
    return get(admin.domain, admin.loanApplicationNotes, { appId })
  },
  loanApplicationAddNote(appId: string, note: string) {
    return post(admin.domain, admin.loanApplicationAddNote, { appId, note })
  },
  loanApplicationDeleteNote(appId: string, noteId: string) {
    return delete_(admin.domain, admin.loanApplicationDeleteNote, {
      appId,
      noteId,
    })
  },

  saveCompanyCreditSettings({
    company_id,
    limit,
    purchaseType,
  }: {
    company_id: string
    limit?: number
    purchaseType?: PurchaseTypes
  }) {
    return post(admin.domain, admin.saveCompanyCreditSettings.name, {
      company_id,
      limit,
      purchaseType,
    })
  },

  downloadLoanReport(dateStart: string, dateEnd: string) {
    return post(admin.domain, admin.downloadLoanReport, { dateStart, dateEnd })
  },

  downloadS3Report(fileName: string) {
    return get(admin.domain, admin.downloadS3Report, { fileName })
  },
  downloadLoanActivity() {
    return get(admin.domain, admin.downloadLoanActivity)
  },
  downloadEomReport(params: { date: string }) {
    return get(admin.domain, admin.downloadEomReport, params)
  },
  downloadLoanApprovalReport(params: { date_start: string; date_end: string }) {
    return get(admin.domain, admin.downloadLoanApprovalReport, params)
  },
  downloadLoanPrequalReport(params: { date_start: string; date_end: string }) {
    return get(admin.domain, admin.downloadLoanPrequalReport, params)
  },
  downloadExperianReport() {
    return get(admin.domain, admin.downloadExperianReport)
  },

  loanPayment(
    loanId: string,
    data: {
      method: string
      amount: string
      bankAccountId?: string | null
      manualBankAccount?: { accountNumber: string; routingNumber: string }
      manualCard?: any
    },
  ) {
    return post(this.domain, this.loanPayment, { id: loanId, ...data })
  },

  updateLoanReceivableDate(
    receivableId: string,
    date: string,
    note: string,
    userId: string,
  ) {
    return post(admin.domain, admin.updateLoanReceivableDate, {
      receivableId,
      date,
      note,
      userId,
    })
  },
  addPaymentToHistory(
    loanId: string,
    amount: number,
    date: string,
    note: string,
    userId: string,
  ) {
    return post(admin.domain, admin.addPaymentToHistory, {
      loanId,
      amount,
      note,
      date,
      userId,
    })
  },
  addLoanRefund(
    loanId: string,
    amount: number,
    date: string,
    note: string,
    userId: string,
  ) {
    return post(admin.domain, admin.addLoanRefund, {
      loanId,
      amount,
      date,
      note,
      userId,
    })
  },
  changeLoanStatus(
    loanId: string,
    status: string,
    note: string,
    userId: string,
  ) {
    return post(admin.domain, admin.changeLoanStatus, {
      loanId,
      status,
      note,
      userId,
    })
  },
  rescheduleLoanReceivables(
    loanId: string,
    receivables: IReceivablesRescheduleItem[],
    note: string,
    userId: string,
  ) {
    return post(admin.domain, admin.rescheduleLoanReceivables, {
      loanId,
      receivables,
      note,
      userId,
    })
  },
  addFee(
    loanId: string,
    type: RepaymentType,
    amount: number,
    date: string,
    note: string,
    userId: string,
  ) {
    return post(admin.domain, admin.addFee, {
      loanId,
      type,
      amount,
      note,
      userId,
      date,
    })
  },
  cancelPaymentFromHistory(paymentId: string, note: string, userId: string) {
    return put(admin.domain, admin.cancelPaymentFromHistory, {
      paymentId,
      note,
      userId,
    })
  },
  cancelFee(
    loanReceivableId: string,
    type: RepaymentType,
    note: string,
    userId: string,
  ) {
    return patch(admin.domain, admin.cancelFee, {
      loanReceivableId,
      type,
      note,
      userId,
    })
  },
  updateFeeExpectedAmount(
    loanReceivableId: string,
    type: RepaymentType,
    expectedAmount: number,
    note: string,
    userId: string,
  ) {
    return patch(admin.domain, admin.updateFeeExpectedAmount, {
      loanReceivableId,
      type,
      expectedAmount,
      note,
      userId,
    })
  },
  sendBackApplication(id: string) {
    return post(admin.domain, admin.sendBackApplication, { id })
  },
  calculateFee(
    id: string,
    amount: string,
    token: string,
    firstName: string,
    lastName: string,
    phone: string,
  ) {
    return post(this.domain, this.calculateFee, {
      id,
      amount,
      token,
      firstName,
      lastName,
      phone,
    })
  },

  blockedInstitutions() {
    return get<{ id: string; name: string }[]>(
      admin.domain,
      admin.blockedInstitutions,
    )
  },

  addBlockedInstitution(id: string) {
    return post<{ result: string }>(admin.domain, admin.blockedInstitutions, {
      id,
    })
  },

  removeBlockedInstitution(id: string) {
    return delete_<{ result: string }>(
      admin.domain,
      admin.blockedInstitutions,
      { id },
    )
  },

  blockedRoutingNumbers() {
    return get<string[]>(admin.domain, admin.blockedRoutingNumbers)
  },

  addBlockedRoutingNumber(routingNumber: string) {
    return post<{ result: string }>(admin.domain, admin.blockedRoutingNumbers, {
      routingNumber,
    })
  },

  removeBlockedRoutingNumber(routingNumber: string) {
    return delete_<{ result: string }>(
      admin.domain,
      admin.blockedRoutingNumbers,
      { routingNumber },
    )
  },

  institutions(search: string, limit: number) {
    return get<{
      items: (IInstitutionModel & { _id: string })[]
      result: string
    }>(admin.domain, admin.institutions, { search, limit })
  },

  getCompany(id: string): Promise<ISupplier> {
    return get(admin.domain, admin.getCompany, { id })
  },

  getCompanySettings(company_id: string) {
    return get(admin.domain, admin.getCompanySettings, { company_id })
  },
  saveCompanySettings(
    company_id: string,
    settings: Record<string, any>,
    credit?: { issueLimit: number },
  ) {
    return post(admin.domain, admin.saveCompanySettings, {
      company_id,
      settings,
      credit,
    })
  },

  getSupplierAutoTradeCreditSettings(id: string) {
    return get<IAutoTradeCreditSettings>(
      admin.domain,
      admin.getSupplierAutoTradeCreditSettings,
      { id },
    )
  },
  updateSupplierAutoTradeCreditSettings(data: {
    id: string
    settings: IAutoTradeCreditSettings
  }) {
    return post(admin.domain, admin.updateSupplierAutoTradeCreditSettings, data)
  },
  getCustomerSettings(id: string) {
    return get<ICustomerSettings>(admin.domain, admin.getCustomerSettings, {
      id,
    })
  },
  updateCustomerSettings(data: {
    id: string
    settings: ICustomerSettings
    data: CustomerAccountInfo
  }) {
    return post(admin.domain, admin.updateCustomerSettings, data)
  },
  getSupplierCustomers(
    companyId: string,
    search: string,
    page: number,
    limit: number,
    params: {
      tradeCredit: string
      loanPaymentCollection: string
    },
  ) {
    return get<{
      items: CustomerAccountInfo[]
      total: number
      companyName: string
      autoTradeCreditEnabled: boolean
      isSupplierPaymentInfoExists: boolean
    }>(admin.domain, admin.getSupplierCustomers, {
      id: companyId,
      page: page,
      limit: limit,
      search: search,
      ...params,
    })
  },
  // see implementation here packages/server/src/routes/v1/admin.route/supplier/customers.ts::deleteSupplierCustomers
  deleteSupplierCustomers(id: string) {
    return delete_(admin.domain, admin.deleteSupplierCustomers, { id })
  },

  getCustomerInvoicesCount(id: string) {
    return get(admin.domain, admin.getCustomerInvoicesCount, { id })
  },
}
