export const OPERATION_TYPES = {
  INVOICE: {
    PAYMENT: 'invoice_payment',
    FINAL_PAYMENT: 'invoice_final_payment',
    REFUND: 'invoice_refund',
    VOID: 'invoice_void',
  },
  LOAN: {
    ISSUE: 'loan_issue',
    FINAL_ISSUE: 'loan_final_issue',
    BUYBACK: 'loan_buyback',
    CANCEL: 'loan_cancel',
    REPAYMENT: 'loan_repayment',
    SERVICE: 'loan_service',
    REFUNDWITHHELD: 'refund_withheld',
  },
  ACH: {
    PULL: 'ach_pull',
    PAYMENT: 'ach_payment',
    OUT: 'ach_out',
    INTERNAL: 'ach_internal',
  },
} as const

export type OperationTypes =
  | typeof OPERATION_TYPES.INVOICE[keyof typeof OPERATION_TYPES.INVOICE]
  | typeof OPERATION_TYPES.LOAN[keyof typeof OPERATION_TYPES.LOAN]
  | typeof OPERATION_TYPES.ACH[keyof typeof OPERATION_TYPES.ACH]

export const TRANSACTION_TYPES = {
  ACH: { TRANSFER: 'transfer', INTERNAL_TRANSFER: 'internal_transfer' },
  LOAN: {
    DISBURSEMENT: 'disbursement',
    REVENUE: 'revenue',
    REIMBURSEMENT: 'reimbursement',
  },
} as const

export type TransactionTypes =
  | typeof TRANSACTION_TYPES.ACH[keyof typeof TRANSACTION_TYPES.ACH]
  | typeof TRANSACTION_TYPES.LOAN[keyof typeof TRANSACTION_TYPES.LOAN]

export const ACH_TRANSACTION_TYPES = {
  PULL: 'PULL',
  OUT: 'OUT',
} as const

export const TABAPAY_TRANSACTION_TYPES = {
  PULL: 'PULL',
  PUSH: 'PUSH',
} as const
export const PAYMENT_METHODS = {
  CARD: 'card',
  ACH: 'ach',
  LOAN: 'loan',
} as const
export type PaymentMethods =
  typeof PAYMENT_METHODS[keyof typeof PAYMENT_METHODS]

export const OPERATION_STATUS = {
  PLACED: 'PLACED',
  PROCESSING: 'PROCESSING',
  SUCCESS: 'SUCCESS',
  FAIL: 'FAIL',
  DECLINED: 'DECLINED',
}
export const TRANSACTION_STATUS = {
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  CANCELED: 'CANCELED',
  SCHEDULED: 'SCHEDULED',
}
export const SETTLEMENT_STATUS = {
  SCHEDULED: 'SCHEDULED',
  SETTLED: 'SETTLED',
  IN_PROGRESS: 'IN PROGRESS',
  FAILED: 'FAILED',
}
export const PAYMENT_INITIATOR = {
  COMPANY: 'company',
  CUSTOMER: 'customer',
}

export type PaymentInitiator = 'company' | 'customer'
