import { useToggleState } from './useToggleState'
import { useEffect, useRef } from 'react'

/**
 * For Input Fields.
 * @param toListen
 * @returns {{onBlur: (function(): *), ref: React.MutableRefObject<any>, onFocus: (function(): *)}}
 */
export const useManualFocus = (...toListen) => {
  const [focused, onFocus, onBlur] = useToggleState()
  const inputRef = useRef<any>()

  useEffect(() => {
    if (focused && !inputRef?.current?._inputElement?.isFocused()) {
      inputRef.current?._inputElement.focus()
    }
    // todo fix lint issue
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toListen])

  return {
    ref: inputRef,
    onBlur,
    onFocus,
  }
}
