import { get } from '../helpers/caller'

export const tradeCredit = {
  domain: 'tradeCredit',
  allTransactions(params: any) {
    return get(tradeCredit.domain, tradeCredit.allTransactions, params)
  },

  upcomingPayments(params: any) {
    return get(tradeCredit.domain, tradeCredit.upcomingPayments, { ...params })
  },

  pastDuePayments(params: any) {
    return get(tradeCredit.domain, tradeCredit.pastDuePayments, { ...params })
  },

  getCompanyPaymentsStatistics() {
    return get(tradeCredit.domain, tradeCredit.getCompanyPaymentsStatistics)
  },

  inactiveDraws(params: any) {
    return get(tradeCredit.domain, tradeCredit.inactiveDraws, { ...params })
  },

  activeDraws(params: any) {
    return get(tradeCredit.domain, tradeCredit.activeDraws, { ...params })
  },
}
